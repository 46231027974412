import React, { Component, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import MediaQuery from 'react-responsive';

import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

// routes config
import routes from '../routes';

class DefaultLayout extends Component {
  static loading() {
    return <div className="animated fadeIn pt-1 text-center">Loading...</div>;
  }

  /**
   * コンポーネントマウント時の処理
   */
  componentWillMount() {
  }

  signOut(e) {
    e.preventDefault();
    this.props.logout();
    localStorage.removeItem('refresh_token');
    this.props.history.push('/login');
  }

  render() {
    return (
      <LoadingOverlay
        active={this.props.loading}
        spinner
        text="Loading..."
      >
        <div className="app">
          <DefaultHeader onLogout={e => this.signOut(e)} />
          <div className="app-body">
            <main className="main">
              {
                this.props.location.pathname === '/'
                  ? (
                    <Container fluid>
                      <Suspense fallback={this.constructor.loading()}>
                        <Switch>
                          {routes.map(route => (route.component ? (
                            <Route
                              key={route.path}
                              path={route.path}
                              exact={route.exact}
                              name={route.name}
                              render={props => (
                                <route.component {...props} />
                              )}
                            />
                          ) : (null)))}
                          <Redirect from="/" to="/" />
                        </Switch>
                      </Suspense>
                    </Container>
                  )
                  : (
                    <div>
                      <MediaQuery query="(max-width: 767px)">
                        {/* mobile */}
                        <Container fluid>
                          <Suspense fallback={this.constructor.loading()}>
                            <Switch>
                              {routes.map(route => (route.component ? (
                                <Route
                                  key={route.path}
                                  path={route.path}
                                  exact={route.exact}
                                  name={route.name}
                                  render={props => (
                                    <route.component {...props} />
                                  )}
                                />
                              ) : (null)))}
                              <Redirect from="/" to="/" />
                            </Switch>
                          </Suspense>
                        </Container>
                      </MediaQuery>
                      <MediaQuery query="(min-width: 767px)">
                        {/* PC */}
                        <Container fluid>
                          <Suspense fallback={this.constructor.loading()}>
                            <Switch>
                              {routes.map(route => (route.component ? (
                                <Route
                                  key={route.path}
                                  path={route.path}
                                  exact={route.exact}
                                  name={route.name}
                                  render={props => (
                                    <route.component {...props} />
                                  )}
                                />
                              ) : (null)))}
                              <Redirect from="/" to="/" />
                            </Switch>
                          </Suspense>
                        </Container>
                      </MediaQuery>
                    </div>
                  )
              }
            </main>
          </div>
          <DefaultFooter />
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.loading.loading,
});

export default connect(mapStateToProps)(DefaultLayout);
