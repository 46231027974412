import React from 'react';
// Home
const Home = React.lazy(() => import('./views/Home'));
// Service
const Service = React.lazy(() => import('./views/Service'));
// Contact
const Contact = React.lazy(() => import('./views/Contact'));
// Privacy
const Privacy = React.lazy(() => import('./views/Privacy'));
// About
const About = React.lazy(() => import('./views/About'));
// Company
const Company = React.lazy(() => import('./views/Company'));
// News
const News = React.lazy(() => import('./views/News/components/News'));
const NewsDetails = React.lazy(() => import('./views/News/components/NewsDetails'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  // Home
  {
    path: '/', exact: true, name: 'ホームページ', component: Home,
  },
  // Service
  {
    path: '/service', exact: true, name: 'サービス', component: Service,
  },
  // Contact
  {
    path: '/contact', exact: true, name: 'お問い合わせ', component: Contact,
  },
  // Privacy
  {
    path: '/privacy', exact: true, name: '個人情報保護方針', component: Privacy,
  },
  // About
  {
    path: '/about', exact: true, name: 'アバウト', component: About,
  },
  // Company
  {
    path: '/company', exact: true, name: '会社情報', component: Company,
  },
  // News
  {
    path: '/news', exact: true, name: 'ニュース', component: News,
  },
  {
    path: '/newsDetails', exact: true, name: 'ニュース詳細', component: NewsDetails,
  },
];

export default routes;
