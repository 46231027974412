import * as CONST from '../ActionTypes';

const initialState = {
  isSuccess: false,
  isFailure: false,
  errorMessage: '',
};

function contact(state = initialState, action) {
  switch (action.type) {
    // -----------------
    // メール送信
    // -----------------
    case CONST.SEND_MAIL_SUCCESS:
      return Object.assign(
        {},
        state,
        {
          isSuccess: true,
          isFailure: false,
          errorMessage: '',
        },
      );
    case CONST.SEND_MAIL_FAILURE:
      return Object.assign(
        {},
        state,
        {
          isSuccess: false,
          isFailure: true,
          errorMessage: action.payload,
        },
      );
    // -----------------
    // 結果フラグクリア
    // -----------------
    case CONST.CLEAR_CONTACT_RESULT_FLG:
      return Object.assign(
        {},
        state,
        {
          isSuccess: false,
          isFailure: false,
          errorMessage: '',
        },
      );
    default:
      return state;
  }
}

export default contact;
