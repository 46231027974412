import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import MediaQuery from 'react-responsive';
import { HamburgerCollapse } from 'react-animated-burgers';
import ResponsiveModal from 'react-responsive-modal';
import { withRouter } from 'react-router';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import './Style.css';

import logo from '../assets/img/Brand/logo.png';
import terra1 from '../assets/img/Brand/terra1.png';
import terra2 from '../assets/img/Brand/terra2.png';
import mail from '../assets/img/Brand/mail.png';

const styles = {
  modal: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    overflow: 'none',
    width: '100%',
    padding: '0',
    margin: '0',
    height: '100%',
    minWidth: '100%',
  },
  overlay: {
    backgroundColor: 'white',
    padding: '70px 0 0 5vw',
    height: '60%',
  },
  closeIcon: {
    display: 'none',
  },
};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      isAcordionOpen: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleAcordion = this.toggleAcordion.bind(this);
  }

  // eslint-disable-next-line react/sort-comp
  toggleMenu() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  }

  toggleAcordion() {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ isAcordionOpen: !this.state.isAcordionOpen });
  }


  render() {
    return (
      <>
        <div className="header wrap-margin fixed-top">
          <MediaQuery query="(min-width: 768px)">
            <div>
              <Link to="/">
                <div style={{ position: 'absolute', top: '8px' }}>
                  <img
                    alt="株式会社TERRA"
                    src={logo}
                    height="50"
                  />
                </div>
              </Link>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '-15px',
                  marginBottom: '40px',
                }}
              >
                <div style={{
                  paddingLeft: '10px',
                }}
                >
                  <a
                    href="http://www.terra-coat.jp/"
                    target="blank"
                    style={{
                      border: '2px solid #209fa6',
                      borderRadius: '15px',
                      width: '160px',
                      textAlign: 'center',
                      backgroundColor: 'white',
                      padding: '16px 0',
                      display: 'block',
                    }}
                  >
                    <img
                      alt=""
                      src={terra1}
                      height="16"
                    />
                  </a>
                </div>
                <div style={{
                  paddingLeft: '10px',
                }}
                >
                  <a
                    style={{
                      border: '2px solid #afc259',
                      borderRadius: '15px',
                      width: '160px',
                      textAlign: 'center',
                      backgroundColor: 'white',
                      padding: '16px 0',
                      display: 'block',
                    }}
                    href="https://www.terra-foresta.jp"
                    target="blank"
                  >
                    <img
                      alt=""
                      src={terra2}
                      height="16"
                    />
                  </a>
                </div>
                <Link
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingLeft: '10px',
                    textDecoration: 'none',
                  }}
                  to="/contact"
                >
                  <div style={{
                    border: '2px solid #284c74',
                    borderRadius: '15px',
                    width: '160px',
                    textAlign: 'center',
                    backgroundColor: '#284c74',
                    padding: '10px 0',
                    color: 'white',
                  }}
                  >
                    <div style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                    >
                      <img
                        alt=""
                        src={mail}
                        height="16"
                      />
                      <span style={{ fontSize: '25px' }}>Contact us</span>
                    </div>
                    <span style={{
                      fontSize: '10px',
                      position: 'absolute',
                      top: '27px',
                      right: '22px',
                    }}
                    >
                      お問い合わせはこちら
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </MediaQuery>
          <Navbar light expand="md" style={{ fontWeight: 'bold' }}>
            <MediaQuery query="(max-width: 767px)">
              <NavbarBrand onClick={() => this.props.history.push('/')}>
                <img
                  alt="株式会社TERRA"
                  src={logo}
                  height="40"
                />
              </NavbarBrand>
              <HamburgerCollapse
                isActive={this.state.isMenuOpen}
                toggleButton={this.toggleMenu}
                barColor="white"
              />
              <ResponsiveModal
                open={this.state.isMenuOpen}
                onClose={this.toggleMenu}
                styles={styles}
                animationDuration={1000}
                focusTrapped
                closeIconSize={40}
                showCloseIcon
              >
                <Nav navbar>
                  <NavItem>
                    <NavLink
                      style={{ color: '#333333', fontSize: 24 }}
                      onClick={() => {
                        this.props.history.push('/');
                        this.setState({ isMenuOpen: false });
                      }}
                    >
                      Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="pt-1"
                      style={{ color: '#333333', fontSize: 24 }}
                      onClick={() => {
                        this.props.history.push('/about');
                        this.setState({ isMenuOpen: false });
                      }}
                    >
                      About us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="pt-1"
                      style={{ color: '#333333', fontSize: 24 }}
                      onClick={() => {
                        this.props.history.push('/service');
                        this.setState({ isMenuOpen: false });
                      }}
                    >
                      Service
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="pt-1"
                      style={{ color: '#333333', fontSize: 24 }}
                      onClick={() => {
                        this.props.history.push('/company');
                        this.setState({ isMenuOpen: false });
                      }}
                    >
                      Company
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="pt-1"
                      style={{ color: '#333333', fontSize: 24 }}
                      onClick={() => {
                        this.props.history.push('/news');
                        this.setState({ isMenuOpen: false });
                      }}
                    >
                      News
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="pt-1"
                      style={{ color: '#333333', fontSize: 24 }}
                      onClick={() => {
                        this.props.history.push('/contact');
                        this.setState({ isMenuOpen: false });
                      }}
                    >
                      Contact us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="pt-1"
                      style={{ color: '#333333', fontSize: 24 }}
                      onClick={() => {
                        this.props.history.push('/privacy');
                        this.setState({ isMenuOpen: false });
                      }}
                    >
                      Privacy
                    </NavLink>
                  </NavItem>
                </Nav>
              </ResponsiveModal>
            </MediaQuery>
            <MediaQuery query="(min-width: 768px)">
              <Collapse isOpen navbar className="justify-content-end">
                <Nav className="justify-content-end" navbar>
                  <NavItem className="text-center nav-item-header">
                    <NavLink className="text_shadow" style={{ color: 'white', fontSize: 25 }} exact activeClassName="active" tag={RRNavLink} to="/about">About us</NavLink>
                    <NavLink className="text_shadow" style={{ color: 'white', fontSize: 10 }} exact activeClassName="active" tag={RRNavLink} to="/about">TERRAについて</NavLink>
                  </NavItem>
                  <NavItem className="text-center nav-item-header">
                    <NavLink className="text_shadow" style={{ color: 'white', fontSize: 25 }} exact activeClassName="active" tag={RRNavLink} to="/service">Service</NavLink>
                    <NavLink className="text_shadow" style={{ color: 'white', fontSize: 10 }} exact activeClassName="active" tag={RRNavLink} to="/service">サービス紹介</NavLink>
                  </NavItem>
                  <NavItem className="text-center nav-item-header">
                    <NavLink className="text_shadow" style={{ color: 'white', fontSize: 25 }} exact activeClassName="active" tag={RRNavLink} to="/company">Company</NavLink>
                    <NavLink className="text_shadow" style={{ color: 'white', fontSize: 10 }} exact activeClassName="active" tag={RRNavLink} to="/company">会社情報</NavLink>
                  </NavItem>
                  <NavItem className="text-center nav-item-header">
                    <NavLink className="text_shadow" style={{ color: 'white', fontSize: 25 }} exact activeClassName="active" tag={RRNavLink} to="/news">News</NavLink>
                    <NavLink className="text_shadow" style={{ color: 'white', fontSize: 10 }} exact activeClassName="active" tag={RRNavLink} to="/news">お知らせ</NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </MediaQuery>
          </Navbar>
        </div>
      </>
    );
  }
}

export default withRouter(DefaultHeader);
