/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import {
  Navbar,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import MediaQuery from 'react-responsive';
import { withRouter } from 'react-router';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import './Style.css';
import logo from '../assets/img/Brand/logo.png';


const footerStyle = {
  padding: '35px 0px',
  background: '#284c74',
};

class DefaultFooter extends Component {
  render() {
    return (
      <React.Fragment>
        <footer style={footerStyle} className="footer">
          <div className="wrap-margin">
            <Navbar className="logo-footer" light expand="md" style={{ fontWeight: 'bold' }}>
              <div>
                <img
                  alt="株式会社TERRA"
                  src={logo}
                  height="50"
                />
              </div>
              <MediaQuery query="(min-width: 768px)">
                <Collapse isOpen navbar className="justify-content-end">
                  <Nav className="justify-content-end" navbar>
                    <NavItem className="text-center">
                      <NavLink style={{ color: 'white', fontSize: 25 }} exact activeClassName="active" tag={RRNavLink} to="/about">About us</NavLink>
                      <NavLink style={{ color: 'white', fontSize: 10 }} exact activeClassName="active" tag={RRNavLink} to="/about">TERRAについて</NavLink>
                    </NavItem>
                    <NavItem className="text-center">
                      <NavLink style={{ color: 'white', fontSize: 25 }} exact activeClassName="active" tag={RRNavLink} to="/service">Service</NavLink>
                      <NavLink style={{ color: 'white', fontSize: 10 }} exact activeClassName="active" tag={RRNavLink} to="/service">サービス紹介</NavLink>
                    </NavItem>
                    <NavItem className="text-center">
                      <NavLink style={{ color: 'white', fontSize: 25 }} exact activeClassName="active" tag={RRNavLink} to="/company">Company</NavLink>
                      <NavLink style={{ color: 'white', fontSize: 10 }} exact activeClassName="active" tag={RRNavLink} to="/company">会社情報</NavLink>
                    </NavItem>
                    <NavItem className="text-center">
                      <NavLink style={{ color: 'white', fontSize: 25 }} exact activeClassName="active" tag={RRNavLink} to="/news">News</NavLink>
                      <NavLink style={{ color: 'white', fontSize: 10 }} exact activeClassName="active" tag={RRNavLink} to="/news">お知らせ</NavLink>
                    </NavItem>
                    <NavItem className="text-center">
                      <NavLink style={{ color: 'white', fontSize: 25 }} exact activeClassName="active" tag={RRNavLink} to="/contact">Contact us</NavLink>
                      <NavLink style={{ color: 'white', fontSize: 10 }} exact activeClassName="active" tag={RRNavLink} to="/contact">お問い合わせ</NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </MediaQuery>
            </Navbar>
            <div className="address" style={{ padding: '0 1rem', color: 'white' }}>
              <span>〒 107-0051</span>
              <br />
              <span>東京都港区元赤坂 1-7-18</span>
              <br />
              <span>メットライフ元赤坂イースト 7F</span>
              <br />
              <span>TEL 03-6812-9104 &nbsp; FAX 03-6812-9105</span>
              <br />
              <span>フリーダイヤル&nbsp;&nbsp;0120-406-510</span>
              <Link className="porishi text-white" to="/privacy" style={{ float: 'right', textDecoration: 'none' }}>プライバシーポリシー</Link>
              <br />
              <h6 className="copyright">COPYRIGHT TERRA, ALL RIGHTS RECEIVED.</h6>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default withRouter(DefaultFooter);
